import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Test = () => {
  const params = useParams();

  useEffect(() => {
    console.log(params, "params");
  }, []);
  return <div>Test</div>;
};

export default Test;
