
import { createTheme } from "@mui/material";
import { primaryHeadingColor } from "../utils";

export const defaultTheme = createTheme({
  MuiCssBaseline: {
    styleOverrides: {
      "@font-face": [
        {
          fontFamily: "BrandonRegular",
          fontStyle: "normal",
          fontDisplay: "swap",
          src:
          'url("./assets/Fonts/BrandonGrotesque/Brandon_reg.otf") format("opentype")'
        },
      ]
      }
    },
  breakpoints: {
    values: {
      xs: 600,
      sm: 769,
      md: 1025,
      lg: 1441,
      xl: 2160,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      navBar: "#fff",
    },
    primary: {
      main: "#8FA2AA",
      light: "#ffffff",
      dark: "#58595B",
    },
    neutral: {
      main: "rgb(206,233,239)",
    },
    secondary: {
      main: "#4B4C4E",
      contrastText: "#fff",
    },
    text: {
      primary: "#4B4C4E",
      secondary: "#58595B",
      light: "#8C9A9E",
      exlight: "#A7A9AC",
      link: "#8FA2AA",
      blue: "#2F80ED",
      error: "#EB5757",
    },
  },

  mixins: {
    toolbar: {
      minHeight: "inherit",
    },
  },

  typography: {
    htmlFontSize: 16,
    pxToRem: (size) => `${size / 16}rem`,
    pxToVw: (size) => `${size / 14.4}vw`,
    fontFamily: "BrandonRegular",
    boxSizing: "border-box",

    h1: {
      fontFamily:"BrandonRegular",
      fontSize: "2.125rem", //34
    },
    h2: {
      fontFamily:"BrandonRegular",
      fontSize: "1.625rem", //26
    },
    // h22: {
    //   fontFamily:"BrandonRegular",
    //   fontSize: "32px", //26
    //   fontWeight: 500,
    // },
    h3: {
      fontFamily:"BrandonRegular",
      fontSize: "1.5rem", //24
    },
    h4: {
      fontFamily:"BrandonRegular",
      fontSize: "1.375rem", //22
      color:primaryHeadingColor()
    },
    h5: {
      fontFamily:"BrandonRegular",
      fontSize: "1.25rem", //20
    },
    h6: {
      fontFamily:"BrandonRegular",
      fontSize: "1.125rem", //18
    },
    subtitle1: {
      fontFamily:"BrandonRegular",
      fontSize: "1.125rem", //18
      
    },
    subtitle11: {
      fontFamily:"BrandonRegular",
      fontSize: "1.125rem", //18
      
    },
    subtitle12: {
      fontFamily:"BrandonRegular",
      fontSize: "1.125rem", //18
      fontWeight: 100,
    },
    subtitle2: {
      fontFamily:"BrandonRegular",
      fontSize: "1rem", //16
      
    },
    subtitle3: {
      fontFamily:"BrandonRegular",
      fontSize: "0.875rem", //14
      
    },
    body1: {
      fontFamily:"BrandonRegular",
      fontSize: "1rem", //16
      
    },
    body2: {
      fontFamily:"BrandonRegular",
      fontSize: "15px", //15
      
    },
    body3: {
      fontFamily:"BrandonRegular",
      fontSize: "0.875rem", //14
     
    },
    body4: {
      fontFamily:"BrandonRegular",
      fontSize: "0.875rem", //14
     
    },
    body5: {
      fontSize: "0.844rem", //14
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
          borderRadius: "1.8rem",
        },
        contained: {
          color: "#fff",
        },
        
        outlined: {
          color: "#4B4C4E",
          borderColor: "#4B4C4E",
          border: "2px solid",
          "&:hover": {
            borderColor: "#4B4C4E",
            border: "2px solid",
            // boxShadow:"0 0 10px 0px #4B4C4E"
            
          },
        },
      },
      variants: [
        {
          props: { variant: "dark" },
          style: {
            textTransform: "none",
            backgroundColor: "#4B4C4E",
            color: "#fff",
            border: "2px solid #4b4c4e00",
            "&:hover":{
              background:"#4e4f50ad !important"
            }
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d7d5d5",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E0E0E0",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d7d5d5",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E0E0E0",
          },
        },
      },
    },
  },
});
