import React, { lazy } from "react";
import EditHotelDetailsForm from "../pages/EditHotelDetailsForm";
import Home from "../pages/Home";
import AddAdminForm from "../pages/AddAdminForm";
import AdminList from "../pages/AdminList";
import Test from "../pages/Test";
import RestaurantHome from "../pages/RestaurantHome";
import EditBrandDetailsForm from "../pages/EditBrandDetailsForm";

const Login = lazy(() => import("../pages/Login"));

export const routes = [
  {
    path: "/",
    name: "default",
    component: <Login />,
    default: <Login />,
  },
  {
    path: "/login",
    name: "login",
    component: <Login />,
    default: <Login />,
  },
  {
    path: "/details/:id/:branchID",
    name: "details",
    component: <EditHotelDetailsForm />,
    default: <Login />,
  },
  {
    path: "/adminList",
    name: "adminList",
    component: <AdminList />,
    default: <Login />,
  },
  {
    path: "/addAdmin",
    name: "addAdmin",
    component: <AddAdminForm />,
    default: <Login />,
  },
  {
    path: "/addRestaurant",
    name: "addRestaurant",
    component: <EditHotelDetailsForm />,
    default: <Login />,
  },
  {
    path: "/addBrand",
    name: "addBrand",
    component: <EditBrandDetailsForm />,
    default: <Login />,
  },
  {
    path: "/details/:id/",
    name: "details",
    component: <EditBrandDetailsForm />,
    default: <Login />,
  },
  {
    path: "/home",
    name: "home",
    component: <Home />,
    default: <Login />,
  },
  {
    path: "/restaurants/:brandName",
    name: "restaurants",
    component: <RestaurantHome />,
    default: <Login />,
  },
  {
    path: "/test/:offerId",
    name: "test",
    component: <Test />,
    default: <Login />,
  },
];
