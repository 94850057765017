import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Recommended from "./NewRecommended";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "../../src/styles/homepage.css";
import Modal from "./Modal";
import Slider from "./newSlider";
import { SwiperSlide } from "swiper/react";
import { en } from "../appLabels/engLabels";
import Header from "./Header";
import { Space, Table, Tag } from "antd";
import { AspectRatio } from "@mui/icons-material";
import update from "../assets/admin/pen.png";
import viewAll from "../assets/admin/view.png";
import deleteIcon from "../assets/admin/delete.png";
import { getOffers, getRestaurants } from "../services/fetchApis";

const RestaurantTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(props.data);
  const [isMobile, setIsMobile] = useState(false);
  const [branchId, setBranchId] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [page, setPage] = useState(1);
  useEffect(() => {
    console.log("RestaurnatTable ::", props);
    setLoading(true);
    setTableData(props?.data);
    let pagination = props?.paginationData;
    let totalRecords = pagination[0]?.count_of_branches;
    setTotalRecords(totalRecords);
    setBranchId(props.id);
    setLoading(false);
  }, [props]);

  const fetchRestaurants = async (page) => {
    const offerBody = {
      limit: 10,
      page: page,
      offer_id: props.offer_id,
    };
    console.log(offerBody);
    try {
      setLoading(true);
      const apiRes = await getRestaurants(offerBody);
      setTableData(apiRes?.slice(0, apiRes.length - 1));
      setPage(page);
      let paginationData = apiRes?.pop();
      let pagination = paginationData;
      let totalRecords = pagination[0]?.count_of_branches;
      setTotalRecords(totalRecords);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!loading ? (
        <TableWrapper
          data={tableData}
          offer_id={props.offer_id}
          totalRecords={totalRecords}
          page={page}
          fetchRestaurants={fetchRestaurants}
        />
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </>
  );
};
export default RestaurantTable;

const TableWrapper = (props) => {
  const [data, setData] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (current) => {
    setData(current);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const route = useNavigate();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Brand",
      dataIndex: "branch_id",
      key: "branch_id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    // {
    //   title: "Distance",
    //   dataIndex: "distance",
    //   key: "distance",
    // },
    {
      title: "Contact",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    // {
    //   title: "Cuisines",
    //   key: "cuisines_list",
    //   dataIndex: "cuisines",
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = "geekblue";
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            style={{
              //minWidth: "80px",
              cursor: "pointer",
              border: "2px solid blue",
              padding: "5px",
              borderRadius: "5px",
              color: "blue",
              fontWeight: "bold",
              fontSize: "95%",
            }}
            onClick={() => {
              route(`/details/${props.offer_id}/${record.branch_id}`);
            }}>
            <img src={update} width={"20px"} />
          </div>
          <div
            style={{
              //minWidth: "80px",
              cursor: "pointer",
              border: "2px solid red",
              padding: "5px",
              borderRadius: "5px",
              color: "red",
              fontWeight: "bold",
              fontSize: "95%",
            }}
            onClick={() => {
              handleOpenModal(record);
            }}>
            <img src={deleteIcon} width={"20px"} />
          </div>
        </Space>
      ),
    },
  ];

  return (
    <div className="restaurant-table">
      {console.log(props, "props::")}
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={{
          current: props.page,
          pageSizeOptions: [],
          defaultCurrent: 1,
          total: props.totalRecords,
          onChange: (page) => {
            props.fetchRestaurants(page);
          },
        }}
      />
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <h2>Delete {data?.name} </h2>
        <br />
        <p>
          Are you sure you want to delete branch <b>{data?.name}</b> ?
          {/* <b>{data?.address}</b> */}
        </p>
      </Modal>
    </div>
  );
};
