// import AmxLogo from "./assets/AmxLogo.png";
// import TravxLogo from "./assets/TRAVXLOGO.svg";

export function formatCard(card) {
  return `${card?.firstNumbers}******${card?.lastNumbers}`
    .match(/.{4}/g)
    .join(" ");
}

// export const sites = {
//   3000 : "amex",
//   3001: "travx"
// }
export const sites = {
  3000: "amex",
  3001: "travx",
};

export const getBankName = () => {
  const host = window.location.port;
  // const host = window.location.host;

  if (Object.keys(sites).includes(host)) {
    return sites[host];
  } else {
    return false;
  }
};

const getPort = () => {
  return window.location.port;
  //  return window.location.host;
};

// export const logoLoader = () => {
//   let siteLogo;

//   switch (getPort()) {
//     case "amex-test.travx.io":
//       siteLogo = AmxLogo;
//       break;
//     case "barclays.travx.io":
//       siteLogo = TravxLogo;
//       break;
//     case "hotels-test.travx.io":
//       siteLogo = TravxLogo;
//       break;
//     case "3000":
//       siteLogo = AmxLogo;
//       break;
//     case "3001":
//       siteLogo = TravxLogo;
//       break;

//     default:
//       console.log("here");
//       break;
//   }
//   return siteLogo;
// };

export const primaryColor = () => {
  let sitePrimaryColor;

  switch (getPort()) {
    case "amex-test.travx.io":
      sitePrimaryColor = "#FFF";
      break;
    case "barclays.travx.io":
      sitePrimaryColor = "#8FA2AA";
      break;
    case "hotels-test.travx.io":
      sitePrimaryColor = "#8FA2AA";
      break;
    case "3000":
      sitePrimaryColor = "#fff";
      break;
    case "3001":
      sitePrimaryColor = "#8FA2AA";
      break;

    default:
      console.log("here");
      break;
  }
  return sitePrimaryColor;
};
export const neutralColor = () => {
  let sitePrimaryColor;

  switch (getPort()) {
    case "amex-test.travx.io":
      sitePrimaryColor = "#fff";
      break;
    case "barclays.travx.io":
      sitePrimaryColor = "rgb(206,233,239)";
      break;
    case "hotels-test.travx.io":
      sitePrimaryColor = "rgb(206,233,239)";
      break;
    case "3000":
      sitePrimaryColor = "#fff";
      break;
    case "3001":
      sitePrimaryColor = "rgb(206,233,239)";
      break;

    default:
      console.log("here");
      break;
  }
  return sitePrimaryColor;
};

export const primaryTextColor = () => {
  let sitePrimaryTextColor;

  switch (getPort()) {
    case "amex-test.travx.io":
      sitePrimaryTextColor = "#fff";
      break;
    case "barclays.travx.io":
      sitePrimaryTextColor = "#4B4C4E";
      break;
    case "hotels-test.travx.io":
      sitePrimaryTextColor = "#4B4C4E";
      break;
    case "3000":
      sitePrimaryTextColor = "#fff";
      break;
    case "3001":
      sitePrimaryTextColor = "#4B4C4E";
      break;

    default:
      console.log("here");
      break;
  }
  return sitePrimaryTextColor;
};

export const primaryHeadingColor = () => {
  let sitePrimaryTextColor;

  switch (getPort()) {
    case "amex-test.travx.io":
      sitePrimaryTextColor = "#fff";
      break;
    case "barclays.travx.io":
      sitePrimaryTextColor = "#4B4C4E";
      break;
    case "hotels-test.travx.io":
      sitePrimaryTextColor = "#4B4C4E";
      break;
    case "3000":
      sitePrimaryTextColor = "#fff";
      break;
    case "3001":
      sitePrimaryTextColor = "#4B4C4E";
      break;

    default:
      console.log("here");
      break;
  }
  return sitePrimaryTextColor;
};

export const titleNameP = () => {
  if (getBankName() === "travx") {
    return "Partners";
  } else {
    return "Restaurants";
  }
};
export const titleNameS = () => {
  if (getBankName() === "travx") {
    return "Partner";
  } else {
    return "Restaurant";
  }
};
