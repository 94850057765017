import axios from "axios";

export const getOffers = async (body) => {
  const tos = 1;
  // JSON.parse(localStorage.getItem("tos_accepted"));

  //const cordinate = getCordinatefromLocalStorage();
  const cordinate = { latitude: "51.5104", longitude: "-0.1301" };
  const hotelCoor = {
    hotel_latitude: "51.5184005",
    hotel_longitude: "-0.1348734",
  };

  try {
    const res = await axios.post(
      "https://r1as17701c.execute-api.eu-west-1.amazonaws.com/default/amex_test_travx_get_brand_offers_for_admin",
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "qYJU4okcPm1pyMcbW54X745BpdKZC3sV3TMarnhS",
          //"qBBCk6jwf586MOXY9O3Tc1X4GUmEckvO8FjmIdYy",
        },
      }
    );
    return res.data;
  } catch (err) {}
};

export const getRestaurants = async (body) => {
  try {
    const res = await axios.post(
      "https://mdc2o6tmx3.execute-api.eu-west-1.amazonaws.com/default/amex_test_travx_get_branch_details_for_admin",
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "McVEggldK74BwUyqwkdq03eKOWmZNljz9QAbsu29",
        },
      }
    );
    return res.data;
  } catch (err) {}
};

export const getOfferDetails = async (body) => {
  //const cordinate = getCordinatefromLocalStorage();
  const cordinate = { latitude: "51.5104", longitude: "-0.1301" };
  const hotelCoor = {
    hotel_latitude: "51.5184005",
    hotel_longitude: "-0.1348734",
  };
  try {
    const res = await axios.post(
      "https://smxvrq4o5e.execute-api.eu-west-1.amazonaws.com/default/travx-test-cmb-fetch-offer-details",
      { ...body, ...cordinate, ...hotelCoor },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "6T194Nw79e6BXeVLbDcj99UWxK5kro1I1maQLiAt",
        },
      }
    );
    return res;
  } catch (err) {}
};

export const getOfferDetailsBrand = async (body) => {
  //const cordinate = getCordinatefromLocalStorage();
  const cordinate = { latitude: "51.5104", longitude: "-0.1301" };
  const hotelCoor = {
    hotel_latitude: "51.5184005",
    hotel_longitude: "-0.1348734",
  };
  try {
    const res = await axios.post(
      "https://barclays.travx.io/offer/all?offer=10042&brand=M%20Restaurants",
      { ...body, ...cordinate, ...hotelCoor },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "6T194Nw79e6BXeVLbDcj99UWxK5kro1I1maQLiAt",
        },
      }
    );
    return res;
  } catch (err) {}
};

//
