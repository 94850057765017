import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  Link,
  Stack,
  Typography,
  Snackbar,
  IconButton,
  Backdrop,
  InputAdornment,
} from "@mui/material";
import Logo from "../assets/TRAVXLOGO.svg";
import {
  Contain,
  InputField1,
  LoginButton,
  SignupButton,
} from "../styles/styles";
import { useNavigate } from "react-router-dom";
// import { getCustomerId, loginApi } from "../services/AuthApi";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
// import { gClientId } from "../AppConfig";
import jwt_decode from "jwt-decode";
import { en } from "../appLabels/engLabels";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { getCardApi } from "../services/CardApi";
import { useAuth } from "../../src/AuthContext.js";
const Login = () => {
  const route = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const defaultNotify = { status: false, message: "" };
  const [notify, setNotify] = React.useState(defaultNotify);
  const [showPassword, setShowPassword] = React.useState(false);

  const { authenticated, login, logout } = useAuth();
  console.log("authenticated", authenticated, login);
  console.log(
    "env vars",
    process.env.REACT_APP_DUMMY_EMAIL,
    process.env.REACT_APP_DUMMY_PASSWORD
  );
  const handleLogin = async () => {
    if (
      loginValue.email === process.env.REACT_APP_DUMMY_EMAIL &&
      loginValue.password === process.env.REACT_APP_DUMMY_PASSWORD
    ) {
      sessionStorage.setItem("authStatus", true);
      login();
      route("/home");
    }
  };
  const initialErrorState = {
    email: "",
    password: "",
  };

  const [loginValue, setLoginValue] = React.useState({
    email: "",
    password: "",
  });
  const [error, setError] = React.useState(initialErrorState);

  useEffect(() => {
    if (document.getElementById("cookieyes")) {
      document.getElementById("cookieyes").remove();
    }
    const toknId = JSON.parse(localStorage.getItem("userDetails"));
    const isGlogin = localStorage.getItem("isLoginWithGoogle");
    const urlVerify = JSON.parse(localStorage.getItem("urlverified"));

    // if (toknId?.userId || isGlogin) {
    // 	route("/home");
    // }
    // if (!urlVerify) {
    // 	route("/");
    // }
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const clientId = gClientId;

  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const handleChange = (name) => (event) => {
    setError(initialErrorState);
    const val = event.target.value;
    // validate({ [name]: val });
    setLoginValue({ ...loginValue, [name]: val });
  };

  // const submitLoginForm = async () => {
  // 	const hotel_location = JSON.parse(
  // 		localStorage.getItem("hotel_location")
  // 	);
  // 	const loginBody = {
  // 		username: loginValue.email,
  // 		password: loginValue.password,
  // 	};

  // 	if (!validate(loginValue)) {
  // 		return;
  // 	}
  // 	setLoading(true);

  // 	const loginRes = await loginApi(loginBody);

  // 	if (loginRes.status === 200) {
  // 		localStorage.setItem(
  // 			"accessToken",
  // 			JSON.stringify(loginRes.data.Access_token)
  // 		);
  // 		const accessToken = await getCustomerId({
  // 			email: loginBody.username,
  // 			hotel_latitude: hotel_location.latitude,
  // 			hotel_longitude: hotel_location.longitude,
  // 		});

  // 		if (accessToken.status === 200) {
  // 			const userInfo = {
  // 				userId: accessToken.data.customer_id,
  // 				userEmail: loginBody.username,
  // 			};
  // 			localStorage.setItem("userDetails", JSON.stringify(userInfo));
  // 			const userCard = await getCardApi(
  // 				accessToken.data.customer_id
  // 			);
  // 			// if (userCard && userCard.status == 200) {
  // 			// 	if (userCard.data.count > 0) {
  // 			// 		route("/home");
  // 			// 	} else {
  // 			// 		route("/cards");
  // 			// 	}
  // 			// } else {
  // 			// 	route("/home");
  // 			// }
  // 			setLoading(false);

  // 		}
  // 	} else if (loginRes.status === 400) {
  // 		setNotify({
  // 			...notify,
  // 			status: true,
  // 			message: "Invalid Email or Password",
  // 		});
  // 	} else {
  // 		setNotify({
  // 			...notify,
  // 			status: true,
  // 			message: "Somthing went Wrong!",
  // 		});
  // 	}
  // };

  // // Goolge Login
  // const onSuccess = async (res) => {
  // 	setLoading(true);
  // 	const hotel_location = JSON.parse(
  // 		localStorage.getItem("hotel_location")
  // 	);
  // 	const decodedRes = jwt_decode(res.credential);

  // 	const customerId = await getCustomerId({
  // 		email: decodedRes.email,
  // 		hotel_latitude: hotel_location.latitude,
  // 		hotel_longitude: hotel_location.longitude,
  // 	});

  // 	if (customerId.status === 200) {
  // 		const gUser = {
  // 			userId: customerId.data.customer_id,
  // 			userEmail: decodedRes.email,
  // 		};
  // 		localStorage.setItem("userDetails", JSON.stringify(gUser));
  // 		localStorage.setItem("isLoginWithGoogle", true);
  // 		const userCard = await getCardApi(
  // 			customerId.data.customer_id
  // 		);
  // 		// if (userCard && userCard.status == 200) {
  // 		// 	if (userCard.data.count > 0) {
  // 		// 		route("/home");
  // 		// 	} else {
  // 		// 		route("/cards");
  // 		// 	}
  // 		// } else {
  // 		// 	route("/home");
  // 		// }
  // 		setLoading(false);

  // 	}
  // };

  return (
    <Contain
      maxWidth="xl"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Stack
        className="mvh-100 auth-box"
        // style={{
        // 	display : 'flex',
        // 	alignItems : 'center',
        // 	justifyContent : 'center'
        // }}
        justifyContent="space-between"
        // maxWidth={"50%"}
        gap="1.25rem">
        <Box>
          <Box sx={{ padding: "2rem 0 0 0" }}>
            <Typography
              as="p"
              variant="h3"
              fontWeight={400}
              textAlign="center"
              className="brandom-medium"
              fontSize={"18px"}
              marginBottom={"17px"}
              letterSpacing="2px">
              {en.LoginTitle}
            </Typography>
            <Box className="logo-frame">
              <img
                src={Logo}
                alt="logo"
                style={{ width: "85%", margin: "auto" }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="mb-4">
            <FormControl fullWidth>
              <Typography
                as="p"
                variant="h4"
                color="text.primary"
                className="brandomtxt-regular mb-3">
                {en.loginEmailTitle}
              </Typography>
              <InputField1
                type="text"
                name="email"
                placeholder={en.loginEmailplaceholder}
                value={loginValue.email}
                onChange={handleChange("email")}
                error={error.email ? true : false}
              />
              {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
            </FormControl>
          </Box>
          <Box className="mb-4">
            <FormControl fullWidth>
              <Typography
                as="p"
                variant="h4"
                color="text.primary"
                className="brandomtxt-regular mb-3">
                {en.loginPasswordTitle}
              </Typography>
              <InputField1
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={en.loginPasswordplaceholder}
                value={loginValue.password}
                onChange={handleChange("password")}
                error={error.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        aria-label="toggle password visibility"
                        edge="end">
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* {error.password ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.password}
								</Typography>
							) : null} */}
            </FormControl>
          </Box>
          <Box className="mb-3">
            <Link
              // href='/reset-password'
              style={{
                fontSize: "14px",
                color: "#4B4C4E",
                opacity: "0.5",
                textDecoration: "underline",
              }}
              className="brandomtxt-regular">
              {en.forgotPasswordTxt}
            </Link>
          </Box>
          {/* <Box className='mb-3'>
						<Typography variant='body4' as='p'>
							By signing in you accept the{" "}
							<a
								href='/terms-service'
								style={{ color: "#8FA2AA" }}>
								<u>Terms of service</u>
							</a>{" "}
							and acknowledge the{" "}
							<a
								href='/privacy-policy'
								style={{ color: "#8FA2AA" }}>
								<u>Privacy Policy</u>
							</a>{" "}
							and{" "}
							<a
								href='/cookie-policy'
								style={{ color: "#8FA2AA" }}>
								<u>Cookie Policy</u>
							</a>
							.
						</Typography>
					</Box> */}
        </Box>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            className="auth-stack">
            <LoginButton
              variant="dark"
              onClick={() => {
                handleLogin();
                //route('/home')
              }}
              className="brandomtxt-medium">
              {en.loginBtn}
            </LoginButton>
            {/* <SignupButton
							variant='outlined'
							className='brandomtxt-medium'
							onClick={() => route("/create-account")}>
							{en.loginSignupbtn}
						</SignupButton> */}
          </Stack>
        </Box>

        <div id="g_id_onload" style={{ margin: "0 auto" }}></div>

        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="filled_black"
          data-text="continue_with"
          data-size="large"
          data-logo_alignment="left"></div>
        <Box>
          <Box textAlign="center" className="pb-3"></Box>
        </Box>
      </Stack>
      {/* <Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Snackbar
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={notify.status}
				autoHideDuration={1000}
				onClose={closeNotify}
				message={notify.message}
				TransitionComponent={SlideTransition}
				action={
					<>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={closeNotify}>
							<CloseIcon fontSize='small' />
						</IconButton>
					</>
				}
			/> */}
    </Contain>
  );
};

export default Login;
