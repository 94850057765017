import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useAuth } from "./AuthContext";
import LinearProgress from "@mui/material/LinearProgress";
import { routes } from "./router";
import Login from "../pages/Login";
import { useAuth } from "../AuthContext";

const ScrollToTop = lazy(() => import("./ScrollToTop"));
// const authStatus = sessionStorage.getItem("authStatus");
// const authenticated = !!authStatus;

const Router = () => {
  const { authenticated } = useAuth();

  return (
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {routes.map((route) =>
            route.name === "default" || route.name === "login" ? (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ) : authenticated ? (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                element={route.default}
              />
            )
          )}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
