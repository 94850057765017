import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Recommended from "../components/NewRecommended";
// import RecommendedBlue from "../../components/homepage/RecommendedBlue";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "../../src/styles/homepage.css";

import Slider from "../components/newSlider";
import { SwiperSlide } from "swiper/react";
// import { getUserInfo } from "../../auth";
import { getOffers } from "../services/fetchApis";
import { bulkUploadApi } from "../services/bulkupload";
// import ErrorBoundary from "../../ErrorBoundary";
import { en } from "../../src/appLabels/engLabels";
import Header from "../components/Header";
import { Space, Table, Tag } from "antd";
import BranchTable from "../components/BranchTable";
import addBtn from "../assets/addBtn.png";
import cloudUpload from "../assets/cloud-upload.png";
import * as XLSX from "xlsx";
import Joi from "joi";
import { toast } from "react-toastify";
import { uploadFileToS3 } from "../utils/aws/awssecretmanager";
const Home = () => {
  const [recommendedloading, setrecommendedLoading] = useState(true);
  const [recommendedForYou, setRecommendedForYou] = useState([]);
  const [count, setCount] = useState(0);
  const [paginationData, setPaginationData] = useState();
  const route = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const schema = Joi.array().items({
    brand_name: Joi.string().required(),
    booking_link: Joi.string(), // Assuming it's a URL
    creatives: Joi.string().required(),
    copy: Joi.string().required(),
    disc_per: Joi.number().required(),
    offer_category: Joi.string().required(),
    offer_start_date: Joi.date().required(), // Assuming it's a date in ISO format
    offer_end_date: Joi.date().required(), // Assuming it's a date in ISO format
    tp_rank: Joi.number().required(),
    branch_name: Joi.string().required(),
    address: Joi.string().required(),
    gmap_address: Joi.string().required(),
    contact_number: Joi.string(),
    cuisine_list: Joi.string().required(),
    num_reviews: Joi.number().required(),
    price_range: Joi.string().required(),
    rating: Joi.number().required(),
    postcode: Joi.string().required(), // Assuming it's optional
    tp_url: Joi.string(), // Assuming it's a URL
  })

  useEffect(() => {
    recommendForYou();
    setrecommendedLoading(false);
  }, []);

  const recommendForYou = async () => {
    setrecommendedLoading(true);
    const offerBody = {
      limit: 10,
      page: 1,
    };
    try {
      const apiRes = await getOffers(offerBody);
      setRecommendedForYou(apiRes?.slice(0, apiRes.length - 1));
      setPaginationData(apiRes?.pop());
      setCount(apiRes?.count);
      setrecommendedLoading(false);
    } catch (error) {
      setrecommendedLoading(false);
      console.log("error ::", error);
    }
    setrecommendedLoading(false);
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file

  // Reading the xlsx file for changes
  const handleImageChange = async (event) => {
    console.log("handleIMageCHange function ran :::");
    try {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded, "file");

      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        console.log("parsedDATA ::", parsedData);
        const result = schema.validate(parsedData);
        console.log(result, 'result')
        if (result.error) {
          toast.error(`Validation error: ${result.error.details[0].message}`);
        } else {
          const url = await uploadFileToS3(fileUploaded);
          if (url) {
            bulkUploadApi({
              s3_url : url
            })
          }
        }
      };

      reader.readAsBinaryString(fileUploaded);
      console.log(fileUploaded.name)
      
    } catch (error) {
      console.error("Error handling the file:", error);
      toast.error("An error occurred while processing the file.");
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          margin: "20px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid grey",
        }}>
        <div
          style={{
            fontSize: "32px",
          }}>
          Brand List
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              route("/addBrand");
            }}>
            <img src={addBtn} width={"50px"} />
            Add
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClick();
              //route("/addBrand");
            }}>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <img src={cloudUpload} width={"50px"} />
            Bulk Upload
          </div>
        </div>
      </div>
      {recommendedForYou.length > 0 ? (
        <BranchTable data={recommendedForYou} paginationData={paginationData} />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default Home;
