import React from "react";

import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination,Navigation } from "swiper";
import ErrorBoundary from "../ErrorBoundary";

const Slider = (props) => {
  return (
    <ErrorBoundary>
      <Swiper
        slidesPerView={props.slide ? "auto" : "1.2"}
        spaceBetween={props.slide ? 10 : 15}
        className={props.catClass ? `${props.catClass} mySwiper` : "mySwiper"}
        breakpoints={{
          640: {
            slidesPerView: props.slide ? "auto" :1.2,
          },
          768: {
            slidesPerView: props.slide ? "auto" :2.2,
          },
          1024: {
            slidesPerView: props.slide ? "auto" :4,
            
          },
        }}
        modules={[Pagination,Navigation]}
        navigation={props.slide ? false:true}
      >
        {props.children}
      </Swiper>
    </ErrorBoundary>
  );
};

export default Slider;
