import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import "../../src/styles/homepage.css";
import Modal from "./Modal";
import { getOffers, getOfferDetails } from "../services/fetchApis";
import { Space, Table, Tag } from "antd";
import update from "../assets/admin/pen.png";
import viewAll from "../assets/admin/view.png";
import deleteIcon from "../assets/admin/delete.png";
const BranchTable = (props) => {
  const [totalRecords, setTotalRecords] = useState();
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const route = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // setLoading(true);
    let data = props.data;
    let paginationData = props.paginationData;
    console.log("data:::", data, paginationData);
    let totalRecords = paginationData[0].count_of_brands;
    setTotalRecords(totalRecords);
    setTableData(data);
    setLoading(false);
  }, []);

  const fetchRecords = async (page) => {
    setPage(page);
    const offerBody = {
      limit: 10,
      //pageSize,
      page: page,
    };
    setLoading(true);
    try {
      const apiRes = await getOffers(offerBody);
      setTableData(apiRes.slice(0, apiRes.length - 1));
      let paginationData = apiRes.pop();
      let totalRecords = paginationData[0].count_of_brands;
      setTotalRecords(totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const dummyData = [
    {
      key: 0,
      brand: "Giraffe",
      rating: 4.5,
      address: "Belvedere Road The South Bank Centre,  London SE1 8XX England",
      tags: ["International"],
      offer_id: "1111",
      branch_id: "6191db94a0a59d74fd4a069e",
    },
    {
      key: 1,
      brand: "Ino",
      rating: 4,
      address: "4 Newburgh Street,  London W1F 7RF",
      tags: ["Mediterranean"],
      offer_id: "1119",
      branch_id: "62394ce01aba9113cf3c98a0",
    },
    {
      key: 2,
      brand: "Park Row",
      rating: 4.2,
      address: "77 Brewer Street,  London W1F 9ZN England",
      tags: ["International", "Fine Dining"],
      offer_id: "1061",
      branch_id: "623998021aba9113cf3e00fc",
    },
    {
      key: 3,
      brand: "El Norte",
      rating: 4.5,
      address: "19-20 Dover Street,  London W1S 4LU",
      tags: ["Spanish", "Fine Dining"],
      offer_id: "1109",
      branch_id: "KMID10354",
    },
    {
      key: 4,
      brand: "Block Soho",
      rating: 3.9,
      address: "2 St. Annes Court,  London W1F 0AZ England",
      tags: ["Steak", "British"],
      offer_id: "1014",
      branch_id: "KMID10257",
    },
    {
      key: 5,
      brand: "Quaglinos",
      rating: 4,
      address: "16 Bury Street,  London SW1Y 6AJ England",
      tags: ["European", "Fine Dining"],
      offer_id: "1066",
      branch_id: "619c5d5ba0a59d74fd7c6059",
    },
    {
      key: 6,
      brand: "Obicà Mozzarella Bar, Pizza e Cucina",
      rating: 4,
      address: "19-20 Poland Street,  London W1F 8QF England",
      tags: ["Italian"],
      offer_id: "1058",
      branch_id: "6190e7a9a0a59d74fd3cb8f4",
    },
    {
      key: 7,
      brand: "Smith & Wollensky",
      rating: 4.5,
      address:
        "The Adelphi Building,  1-11 John Adam Street,  London WC2N 6HT England",
      tags: ["Fine Dining", "Seafood", "Steak"],
      offer_id: "1077",
      branch_id: "619cba85a0a59d74fd817ce1",
    },
    {
      key: 8,
      brand: "Skylon",
      rating: 4,
      address: "Belvedere Road Royal Festival Hall,  London SE1 8XX England",
      tags: ["British", "European", "Fine Dining"],
      offer_id: "1076",
      branch_id: "6190ccbaa0a59d74fd3b40dd",
    },
    {
      key: 9,
      brand: "Sartoria",
      rating: 4,
      address: "20 Savile Row,  London W1S 3PR England",
      tags: ["Fine Dining", "Italian"],
      offer_id: "1073",
      branch_id: "6190f76ca0a59d74fd3d94d4",
    },
  ];

  return !loading && tableData.length > 0 ? (
    <TableWrapper
      data={tableData}
      page={page}
      totalRecords={totalRecords}
      fetchRecords={fetchRecords}
    />
  ) : (
    <div>
      <CircularProgress />
    </div>
  );
};
// return  <TableWrapper data={data2} />
export default BranchTable;

const TableWrapper = (props) => {
  const [data, setData] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (current) => {
    setData(current);
    //console.log(current);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const route = useNavigate();

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Offer Name",
      dataIndex: "offer_name",
      key: "offer_name",
    },
    {
      title: "Offer Category",
      dataIndex: "offer_category",
      key: "offer_category",
      render: (_, { offer_category }) => (
        <>
          {offer_category.split(",").map((tag) => {
            let color = "geekblue";
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="medium">
          <div
            style={{
              //minWidth: "80px",
              cursor: "pointer",
              border: "2px solid green",
              padding: "5px",
              borderRadius: "5px",
              color: "green",
              fontWeight: "bold",
              fontSize: "90%",
              margin: "4px",
            }}
            onClick={() => {
              route(`/restaurants/${record.brand_name}`, {
                state: {
                  ...record,
                  id: record.offer_id,
                  name: record.brand_name,
                },
              });
            }}>
            <img src={viewAll} width={"20px"} />
          </div>
          <div
            style={{
              //minWidth: "80px",
              cursor: "pointer",
              border: "2px solid blue",
              padding: "5px",
              borderRadius: "5px",
              color: "blue",
              fontWeight: "bold",
              fontSize: "95%",
              margin: "4px",
            }}
            onClick={() => {
              route(`/details/${record.brand_name}`, {
                state: {
                  ...record,
                },
              });
              //route(`/details/${record.offer_id}/${record.branch_id}`);
            }}>
            <img src={update} width={"20px"} />
          </div>
          <div
            style={{
              //minWidth: "80px",
              cursor: "pointer",
              border: "2px solid red",
              padding: "5px",
              borderRadius: "5px",
              color: "red",
              fontWeight: "bold",
              fontSize: "95%",
              margin: "4px",
            }}
            onClick={() => {
              handleOpenModal(record);
            }}>
            <img src={deleteIcon} width={"20px"} />
          </div>
        </Space>
      ),
    },
  ];

  return (
    <div className="restaurant-table">
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={{
          current: props.page,
          pageSizeOptions: [],
          defaultCurrent: 1,
          total: props.totalRecords,
          onChange: (page) => {
            props.fetchRecords(page);
          },
        }}
      />
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <h2>Delete {data?.brand_name}</h2>
        <br />
        <p>
          Are you sure you want to delete the Brand <b>{data?.brand_name}</b>?
        </p>
      </Modal>
    </div>
  );
};
