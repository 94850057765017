
import React, { useEffect } from "react";
import {
	Box,
	FormControl,
	Link,
	Stack,
	Typography,
	Snackbar,
	IconButton,
	Backdrop,
	InputAdornment,
} from "@mui/material";
import Logo from "../assets/TRAVXLOGO.svg";
import {
	Contain,
	InputField1,
	LoginButton,
	SignupButton,
} from "../styles/styles";
import { useNavigate , useParams } from "react-router-dom";
import { getOfferDetails } from "../services/fetchApis";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../components/Header";

const AddAdminForm = () => {

	const route = useNavigate();
	const [loading, setLoading] = React.useState(false);
	const defaultNotify = { status: false, message: "" };
	const [notify, setNotify] = React.useState(defaultNotify);
	const [showPassword, setShowPassword] = React.useState(false);

	const initialErrorState = {
		email: "",
		password: "",
	};

	const [loginValue, setLoginValue] = React.useState({
   userName : '',
   password : '',
	});
	const [error, setError] = React.useState(initialErrorState);
	
    const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	
	const handleChange = (name) => (event) => {
		setError(initialErrorState);
		const val = event.target.value;
		// validate({ [name]: val });
		setLoginValue({ ...loginValue, [name]: val });
	};

	const resetForm = () =>{
		setLoginValue({
            userName : '',
            password : '',
			 });
		}

  return (
<>
<Header/>
<Contain maxWidth="xl" 
				style={{
					display : 'flex',
					alignItems : 'center',
					justifyContent : 'center'
				}}>
			<Stack
				className='mvh-100 auth-box'
				justifyContent='space-between'
				gap='1.25rem'>
				<Box>
					<Box sx={{ padding: "2rem 0 0 0" }}>
						<Typography
							as='p'
							variant='h3'
							fontWeight={400}
							textAlign='center'
							className='brandom-medium'
							fontSize={"32px"}
							marginBottom={"17px"}
							letterSpacing='2px'>
							Create Admin
						</Typography>
					</Box>
				</Box>
				<Box>
          <Box className='mb-4'>
						<FormControl fullWidth>
							<Typography
								as='p'
								variant='h4'
								color='text.primary'
								className='brandomtxt-regular mb-3'>
								Username /Email
							</Typography>
							<InputField1
								type='text'
								name='userName'
								//placeholder={en.loginEmail//placeholder}
								value={loginValue.userName}
								onChange={handleChange("userName")}
								//error={error.email ? true : false}
							/>
							{/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
						</FormControl>
					</Box>
                    <Box className='mb-4'>
						<FormControl fullWidth>
							<Typography
								as='p'
								variant='h4'
								color='text.primary'
								className='brandomtxt-regular mb-3'>
								Password
							</Typography>
							<InputField1
								type={showPassword ? "text" : "password"}
								name='password'
								placeholder=''
								value={loginValue.password}
								onChange={handleChange("password")}
								error={error.password ? true : false}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={
													handleClickShowPassword
												}
												aria-label='toggle password visibility'
												edge='end'>
												{!showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{/* {error.password ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.password}
								</Typography>
							) : null} */}
						</FormControl>
					</Box> 
				</Box>
				<Box>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						gap='10px'
						className='auth-stack'>
						<LoginButton
							variant='dark'
							// onClick={submitLoginForm}
							className='brandomtxt-medium'>
							Save
						</LoginButton>
                        <LoginButton
							variant='dark'
							onClick={resetForm}
							className='brandomtxt-medium'>
							Reset
						</LoginButton>
					</Stack>
				</Box>

				<div id='g_id_onload' style={{ margin: "0 auto" }}></div>

				<div
					className='g_id_signin'
					data-type='standard'
					data-shape='rectangular'
					data-theme='filled_black'
					data-text='continue_with'
					data-size='large'
					data-logo_alignment='left'></div>
				<Box>
					<Box textAlign='center' className='pb-3'></Box>
				</Box>
			</Stack>
		</Contain>
</>
  )
}

export default AddAdminForm