import React ,{ useState} from 'react'
import Header from '../components/Header'
import { useNavigate } from 'react-router-dom'
import addBtn from "../assets/addBtn.png"
import { Space, Table, Tag } from 'antd';

const AdminList = () => {
    const route = useNavigate();
    const [data,setData] = useState([]);


    const columns = [
      // {
      //   title: 'Name',
      //   dataIndex: 'name',
      //   key: 'name',
      //   render: (text) => <a>{text}</a>,
      // },
      // {
      //   title: 'Brand',
      //   dataIndex: 'brand',
      //   key: 'brand',
      //   render: (text) => <a>{text}</a>,
      // },
      {
        title: 'UserName',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Password',
        dataIndex: 'password',
        key: 'password',
      },
      // {
      //   title: 'Cuisines',
      //   key: 'cuisines',
      //   dataIndex: 'cuisines',
      //   render: (_, { tags }) => (
      //     <>
      //       {tags.map((tag) => {
      //         let color = tag.length > 5 ? 'geekblue' : 'green';
      //         if (tag === 'loser') {
      //           color = 'volcano';
      //         }
      //         return (
      //           <Tag color={color} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </>
      //   ),
      // },
      // {
      //   title: 'Action',
      //   key: 'action',
      //   render: (_, record) => (
      //     <Space size="middle">
      //       <div  onClick={()=>{
      //                 //console.log("record",record);
      //                 route(`/details/${record.offer_id}/${record.branch_id}`);
      //               }}>
      //         Update</div>
      //       <div onClick={()=>{}}>Delete</div>
      //     </Space>
      //   ),
      // },
    ];
    
    const dummyData = [
      { username : "dummyData@gmail.com" , password: "#wdasnjkbwe"},
      { username : "dummyData@gmail.com" , password: "#wdasnjkbwe"},
      { username : "dummyData@gmail.com" , password: "#wdasnjkbwe"},
      { username : "dummyData@gmail.com" , password: "#wdasnjkbwe"},
    ];
  return (
  <>
   <Header/>
   <div style={{ margin : "20px", display: "flex", justifyContent : 'space-between', borderBottom : '1px solid grey'}}>
      <div style={{
        fontSize : "32px"
      }}>Admin List </div>
      <div
        style={{cursor : 'pointer'}} 
        onClick={()=>{
          route("/addAdmin");
      }}> <img src={addBtn} width={"50px"}/>Add </div>
   </div>
   {!data.length>0 ?
    <div className='admin-table'><Table columns={columns} dataSource={dummyData} /></div>
    :<div>No Items to Show...</div>}
  </>
    
  )
}

export default AdminList