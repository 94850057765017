
import React, { useState, useEffect} from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Stack,
  Toolbar,
  Typography,
  Drawer,
} from "@mui/material";
// import SideBar from "../SideBar";

import Logo from "../../src/assets/travx/logo192.png";

import "../styles/homepage.css";
import { Link, useLocation } from "react-router-dom";

const Header = () => {


  const location = useLocation();
  const pathName = location.pathname;

  return (
    <>
      <AppBar
        sx={{
          background: "#fff",
          boxShadow: "none",
          position: "inherit",
          borderBottom: "1px solid #EAECF0",
          padding: "16px 0",
        }}
        //className="appbarDesktop"
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{ justifyContent: "space-between", padding: "0 !important" }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Link to="/home"
              onClick={()=>{
                // MixPanelTrackNav("Home");
              }}>
                <img src={Logo} alt="" width={"40px"}/>
              </Link>
              <Stack direction={"row"} spacing={4} ml={5}>
                <Link to="/home"
                onClick={()=>{
                //   MixPanelTrackNav("Home");
                }}>
  
                  <Typography
                    fontWeight={600}
                    color={pathName === "/home" ? "#006FCF" : "#475467"}
                  >
                    Home
                  </Typography>
                </Link>
                <Link to="/adminList"
                onClick={()=>{
                //   MixPanelTrackNav("All Brands");
                }}>
                  <Typography
                    fontWeight={600}
                    color={pathName === "/adminList" ? "#006FCF" : "#475467"}
                  >
                    Admin
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      {/* <AppBar
        sx={{
          background: "#fff",
          boxShadow: "none",
          position: "inherit",
          padding: "16px 0",
          borderBottom: "1px solid #EAECF0",
        }}
        className="appbarMobile"
      >
        <Container>
          <Toolbar sx={{ justifyContent: "space-between", padding: 0 }}>
            <>
              <Link to="/home">
                <img src={Logo} alt="" />
              </Link>
            </>

            <Stack direction={"row"} spacing={3}>
              <Link to="/search-page">
                <SearchIcon />
              </Link>
              <MenuIcon onClick={toggleDrawer} />
            </Stack>
            <Drawer
              className="newDrawer"
              anchor="right"
              open={sideBar}
              onClose={toggleDrawer}
            >
              <SideBar close={toggleDrawer} />
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar> */}
    </>
  );
};

export default Header;
