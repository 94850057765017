import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Link,
  Stack,
  Typography,
  Snackbar,
  IconButton,
  Backdrop,
  InputAdornment,
} from "@mui/material";
import {
  Contain,
  InputField1,
  LoginButton,
  SignupButton,
} from "../styles/styles";
import { useNavigate, useParams } from "react-router-dom";
import { getOfferDetails } from "../services/fetchApis";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../components/Header";
import addBtn from "../assets/addBtn.png";

const EditHotelDetailsForm = () => {
  const params = useParams();
  const route = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const defaultNotify = { status: false, message: "" };
  const [notify, setNotify] = React.useState(defaultNotify);
  const initialErrorState = {
    email: "",
    password: "",
  };

  const [loginValue, setLoginValue] = React.useState({
    brandName: "",
    branchAddress: "",
    contactNumber: "",
    cuisines: "",
    discountPercentage: "",
    bookingLink: "",
    offerName: "",
    description: "",
    priceRange: "",
    imageUrl: "",
    coOrdinates: "",
  });
  const [error, setError] = React.useState(initialErrorState);

  const [offer, setOffer] = React.useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  //src={URL.createObjectURL(selectedImage)}
  useEffect(() => {
    if (params.branchID) {
      offerDetails();
    }
  }, [params]);

  const offerDetails = async () => {
    try {
      setLoading(true);
      const body = {
        customer_id: "effa2a83-7cda-4197-bd89-9942912444fc",
        offer_id: params.id,
        is_detailed: 1,
        branch_id: params.branchID,
      };
      const info = await getOfferDetails(body);
      if (info.status === 200) {
        setOffer({ ...info.data });
        console.log("restaurant info:::", info.data);
        setLoginValue({
          brandName: info.data.brand_name,
          branchAddress: info.data.branch_address,
          contactNumber: info.data.branch_contact_number,
          cuisines: info.data.cuisines_list.toString(),
          priceRange: info.data.price_range,
          discountPercentage: info.data.discount_percentage,
          description: info.data.branch_description,
          imageUrl: info.data.offer_creatives,
          bookingLink: info.data.booking_link,
          offerName: info.data.offer_name,
          coOrdinates:
            info.data.branch_latitude.toString() +
            " , " +
            info.data.branch_longitude.toString(),
        });
        setLoading(false);
      }
    } catch (error) {}
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleImageChange = async (event) => {
    console.log("runing");
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      console.log("first");
      const url = URL.createObjectURL(fileUploaded); // Create a URL for the selected file
      console.log("image URL", url);
      setLoginValue({ ...loginValue, imageUrl: url }); // Display the selected image
    }
    console.log(fileUploaded, "file");
    //const url = URL.createObjectURL(fileUploaded);
    //const url = URL.createObjectURL(selectedImage);
    //setLoginValue({ ...loginValue, imageUrl: url });
    //setSelectedImage(fileUploaded);
    //props.handleFile(fileUploaded);
  };
  const handleChange = (name) => (event) => {
    setError(initialErrorState);
    const val = event.target.value;
    // validate({ [name]: val });
    setLoginValue({ ...loginValue, [name]: val });
  };

  const resetForm = () => {
    setLoginValue({
      brandName: "",
      branchAddress: "",
      contactNumber: "",
      cuisines: "",
      priceRange: "",
      discountPercentage: "",
      description: "",
      imageUrl: "",
      bookingLink: "",
      coOrdinates: "",
    });
  };
  return (
    <>
      <Header />
      <Contain
        maxWidth="xl"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Stack
          className="mvh-100 auth-box"
          justifyContent="space-between"
          gap="1.25rem">
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <Box>
            <Box sx={{ padding: "2rem 0 0 0" }}>
              <Typography
                as="p"
                variant="h3"
                fontWeight={400}
                textAlign="center"
                className="brandom-medium"
                fontSize={"32px"}
                marginBottom={"17px"}
                letterSpacing="2px">
                Restaurant Details
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            marginBottom={"30px"}
            alignItems={"center"}>
            <div
              style={{
                width: "80%",
                height: "250px",
                marginBottom: "20px",
              }}>
              {loading ? (
                <>
                  <CircularProgress />
                </>
              ) : (
                <>
                  {loginValue?.imageUrl ? (
                    <img
                      width={"100%"}
                      height={"100%"}
                      style={{
                        borderRadius: "10px",
                        boxShadow: "6px 6px 5px grey",
                      }}
                      src={loginValue.imageUrl}
                      alt="Upload to set an Image"
                    />
                  ) : (
                    <div
                      style={{
                        border: "2px solid grey",
                        width: "100%",
                        cursor: "pointer",
                        borderRadius: "20px",
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                      onClick={() => {
                        handleClick();
                      }}>
                      <img
                        style={{
                          border: "3px dotted grey",
                          borderRadius: "40px",
                        }}
                        src={addBtn}
                        width={"80px"}
                      />
                    </div>
                  )}
                </>
              )}
              {!loading && (
                //loginValue?.imageUrl &&
                <div
                  style={{
                    margin: "10px",
                  }}>
                  <LoginButton
                    variant="dark"
                    onClick={() => {
                      handleClick();
                    }}
                    className="brandomtxt-medium">
                    Upload
                  </LoginButton>
                </div>
              )}
            </div>
          </Box>
          {loading ? (
            <></>
          ) : (
            <>
              {/* <label classname="switch">
                <input type="checkbox" />
                <span classname="slider"></span>
              </label> */}
              <Box marginTop={"30px"}>
                <Box className="mb-4">
                  <FormControl
                    fullWidth
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Brand Enable
                    </Typography>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl
                    fullWidth
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Branch Enable
                    </Typography>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Brand Name
                    </Typography>
                    <InputField1
                      type="text"
                      name="brandName"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.brandName}
                      onChange={handleChange("brandName")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Branch Address
                    </Typography>
                    <InputField1
                      type="text"
                      name="branchAddress"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.branchAddress}
                      onChange={handleChange("branchAddress")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Branch (Lat. , Long.)
                    </Typography>
                    <InputField1
                      type="text"
                      name="coOrdinates"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.coOrdinates}
                      onChange={handleChange("coOrdinates")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Contact Number
                    </Typography>
                    <InputField1
                      type="text"
                      name="contactNumber"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.contactNumber}
                      onChange={handleChange("contactNumber")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Booking Link
                    </Typography>
                    <InputField1
                      type="text"
                      name="bookingLink"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.bookingLink}
                      onChange={handleChange("bookingLink")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Cuisines ( , separated)
                    </Typography>
                    <InputField1
                      type="text"
                      name="cuisines"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.cuisines}
                      onChange={handleChange("cuisines")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Price Range
                    </Typography>
                    <InputField1
                      type="text"
                      name="priceRange"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.priceRange}
                      onChange={handleChange("priceRange")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Offer Name
                    </Typography>
                    <InputField1
                      type="text"
                      name="offerName"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.offerName}
                      onChange={handleChange("offerName")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Discount Percentage
                    </Typography>
                    <InputField1
                      height="100px"
                      type="text"
                      name="discountPercentage"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.discountPercentage}
                      onChange={handleChange("discountPercentage")}
                      //error={error.email ? true : false}
                    />
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Description
                    </Typography>
                    <textarea
                      rows={5}
                      type="text"
                      name="branch_description"
                      value={loginValue.description}
                      onChange={handleChange("description")}
                      className="brandomtxt-regular mb-3"
                      style={{
                        border: "none",
                        fontFamily: "sans-serif",
                        fontSize: "18px",
                        padding: "10px",
                        fontWeight: 400,
                        color: "#58595B",
                        borderRadius: "20px",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  className="auth-stack">
                  <LoginButton
                    variant="dark"
                    // onClick={submitLoginForm}
                    className="brandomtxt-medium">
                    Save
                  </LoginButton>
                  <LoginButton
                    variant="dark"
                    onClick={resetForm}
                    className="brandomtxt-medium">
                    Reset
                  </LoginButton>
                </Stack>
              </Box>
            </>
          )}

          <div id="g_id_onload" style={{ margin: "20px auto" }}></div>
        </Stack>
      </Contain>
    </>
  );
};

export default EditHotelDetailsForm;
