import React, { useEffect, useState } from "react";
import RestaurantTable from "../components/RestaurantTable";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import BranchDetails from "../components/BranchDetails";
import { getOffers, getRestaurants } from "../services/fetchApis";

const RestaurantHome = () => {
  const location = useLocation();
  const [offer_id, setId] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState();
  useEffect(() => {
    //console.log("location.state ::: ", location.state);
    const offer_id = location.state.id;
    setId(offer_id);
    getBrandRestaurants(offer_id);
  }, []);

  const getBrandRestaurants = async (id) => {
    const offerBody = {
      limit: 10,
      page: 1,
      offer_id: id,
    };
    try {
      setLoading(true);
      const apiRes = await getRestaurants(offerBody);
      setData(apiRes?.slice(0, apiRes.length - 1));
      setPaginationData(apiRes?.pop());
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Header />
      <BranchDetails data={location.state} />
      {data.length > 0 && (
        <RestaurantTable
          data={data}
          offer_id={offer_id}
          loading={loading}
          paginationData={paginationData}
        />
      )}
    </>
  );
};

export default RestaurantHome;
