import axios from "axios";

export const bulkUploadApi = async (body) => {

  try {
    axios.post(
      process.env.REACT_APP_BULK_UPLOAD_URL,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_BULK_UPLOAD_KEY,
        },
      }
    );
    return true;
  } catch (err) {}
};
