import React, { useEffect, useState } from "react";
import addBtn from "../assets/addBtn.png";
import { useNavigate } from "react-router-dom";
import cloudUpload from "../assets/cloud-upload.png";
import * as XLSX from "xlsx";
import Joi from "joi";
import { toast } from "react-toastify";
const schema = Joi.object({
  brand_name: Joi.string().required(),
  booking_link_optional: Joi.string().uri(), // Assuming it's a URL
  creatives: Joi.string().required(),
  copy: Joi.string().required(),
  disc_per: Joi.string().required(),
  offer_category: Joi.string().required(),
  offer_start_date: Joi.string().isoDate(), // Assuming it's a date in ISO format
  offer_end_date: Joi.string().isoDate(), // Assuming it's a date in ISO format
  tp_rank: Joi.string().required(),
  branch_name: Joi.string().required(),
  address: Joi.string().required(),
  gmap_address: Joi.string().required(),
  contact_number: Joi.string().required(),
  cuisine_list: Joi.string().required(),
  num_reviews: Joi.string().required(),
  price_range: Joi.string().required(),
  rating: Joi.string().required(),
  postcode_optional: Joi.string(), // Assuming it's optional
  tp_url: Joi.string().uri(), // Assuming it's a URL
});

const BranchDetails = (props) => {
  const [data, setData] = useState();
  const route = useNavigate();
  const [bulkData, setBulkData] = useState();
  useEffect(() => {
    setData(props.data);
  }, []);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file

  const handleImageChange = async (event) => {
    console.log("handleIMageCHange function ran :::");
    try {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded, "file");

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        console.log("parsedDATA ::", parsedData);

        parsedData.forEach((obj) => {
          const result = schema.validate(obj);
          if (result.error) {
            console.error(result.error.details);
            toast.error(`Validation error: ${result.error.details[0].message}`);
          } else {
            console.log("Data is valid:", obj);
          }
        });
      };

      reader.readAsBinaryString(fileUploaded);
    } catch (error) {
      console.error("Error handling the file:", error);
      toast.error("An error occurred while processing the file.");
    }
  };

  return (
    <div
      style={{
        borderBottom: "1px solid grey",
        marginBottom: "20px",
      }}>
      {data && (
        <div
          style={{
            background: "#cee9ef",
            textAlign: "start",
            padding: "20px 5vw",
            fontWeight: "bold",
            fontSize: "18px",
          }}>
          <div
            style={{
              fontSize: "28px",
              fontWeight: "normal",
              marginBottom: "16px",
            }}>
            Branch Details-
          </div>
          <div>
            Brand Name :
            <span
              style={{
                fontWeight: "normal",
                fontSize: "20px",
                marginLeft: "20px",
              }}>
              {data.brand_name}
            </span>
          </div>
          <div>
            Offer Name :
            <span
              style={{
                fontWeight: "normal",
                fontSize: "20px",
                marginLeft: "20px",
              }}>
              {data.offer_name}
            </span>
          </div>
          <div>
            Cuisines :
            <span
              style={{
                fontWeight: "normal",
                fontSize: "20px",
                marginLeft: "20px",
              }}>
              {data.offer_category}
            </span>
          </div>
          <div>
            Offer Id :
            <span
              style={{
                fontWeight: "normal",
                fontSize: "20px",
                marginLeft: "20px",
              }}>
              {data.id}
            </span>
          </div>
        </div>
      )}
      <div
        style={{
          margin: "10px 0",
          padding: "0 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //borderBottom: "0.5px solid grey",
        }}>
        <div
          style={{
            fontSize: "28px",
          }}>
          <div>Restaurant List -</div>
          {/* <div>Restaurant List - {data && data.brand}</div> */}
          {/* <div> {data && data.brand}</div> */}
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              route("/addRestaurant");
            }}>
            <img src={addBtn} width={"50px"} />
            Add
          </div>
          {/* <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClick();
              //route("/addBrand");
            }}>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <img src={cloudUpload} width={"50px"} />
            Bulk Upload
          </div> */}
        </div>
        {/* <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            route("/addRestaurant");
          }}>
          <img src={addBtn} width={"50px"} />
          Add
        </div> */}
      </div>
    </div>
  );
};

export default BranchDetails;
