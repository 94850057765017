import { titleNameP, titleNameS } from "../utils";

export const en = {
  screenSizeNotTitle: "Screen size not supported",
  screenSizeNotSubtitle: "Switch to mobile device to continue",
  invalidURL: "Invalid URL",
  homeRecommendedTitle: "Recommended for you",
  homeCategoryTitle: "Categories",
  viewAll: "View all",
  searchTitle: `Find a ${titleNameS().toLowerCase()}`,
  searchPlaceholder: `Start typing to find ${titleNameP().toLowerCase()}...`,
  mapButton: "Map view",
  savingTitle: "Savings at a glance",
  transactionHistory: "Transaction History",
  recentTransactionCaption: "Recent transactions",
  noTransaction: "No recent transactions found",
  transactionHistortTitle: "Transaction History",
  transactionTitle: "Transactions",
  savingsCaption: "Earnings",
  savingBubble1: "Total earned",
  savingBubble2: "Payout balance",
  payOutBtn: "Payout",
  noFavorites: `No favourite ${titleNameP().toLowerCase()}`,
  hamMenuHome: "Home",
  hamMenuMyTransaction: "My Transactions",
  hamMenuOffers: `${titleNameP()}`,
  hamMenuMyCard: "My Cards",
  hamMenuProifile: "Profile",
  hamMenuLegal: "Legal",
  hamMenuHelp: "Help",
  hamMenuPayout: "Payout",
  footerMenuHome: "Home",
  footerMenuFav: "Favourites",
  footerMenuSearch: "Maps",
  footerMenuSavings: "Savings",
  noOfferFound: `No ${titleNameS().toLowerCase()} found`,
  signOut: "Sign out",
  version: "version 1.0",
  acceptPolity: "Privacy Policy & Data",
  rejectPolicyMsg: "You have rejected our privacy policy",
  accetPolicyBtn: "Accept",
  rejectPolicyBtn: "Reject",
  welcomeTo: "WELCOME TO",
  skipLogin: "Skip to login",
  crtAccount: "Create account",
  curatedoffers: "Curated offers",
  experiences: "& experiences",
  customised: "Customised for you",
  step2cat1: "DINING",
  step2cat2: "RETAIL & SHOPPING",
  step2cat3: "ENTERTAINMENT & MORE",
  step3flow1: "1. REGISTER YOUR CARD",
  step3flow2: "2. SPEND WITH RECOMMENDED OUTLETS",
  step3flow3: "3. GET CASHBACK IN YOUR ACCOUNT",
  signUpHeaderTitle: "Create an account",
  signUpEmailTitle: "Email",
  signUpEmailplaceholder: "Your email address",
  signUpPasswordTitle: "Password",
  signUpPasswordplaceholder: "Enter Password",
  emailRequiredError: "Email is required",
  emailInvalidError: "Email invalid",
  passwordRequiredError: "Password is required",
  passwordMaxlength: "Password must be at least 8 characters long!",
  passwordUppercaseError: "Password must contain 1 Uppercase letter",
  passwordLowercaseError: "Password must contain 1 lowercase letter",
  passwordNumberError: "Password must contain 1 number",
  passwordSpecialError: "Password must contain 1 special character",
  policyAcceptError: "Please accept the policy",
  optError: "OTP is required",
  emailNoExist: "Provided Email do not exists",
  optsendMessage: "An OTP was sent to your email address",
  passwordChangedMessage: "Password has been changed successfully",
  optInputPlaceholder: "Enter OTP sent to your email address",
  newPasswordPlaceholder: "Enter New Password",
  newPasswordTitle: "New Password",
  enterOtp: "Enter OTP",
  LoginTitle: "LOGIN TO",
  loginEmailTitle: "Email",
  loginEmailplaceholder: "Your email address",
  loginPasswordTitle: "Password",
  loginPasswordplaceholder: "Enter Password",
  forgotPasswordTxt: "Forgot your password?",
  loginBtn: "Login",
  loginSignupbtn: "Sign up",
  signuploginBtn: "Sign in",
  signupbtn: "Sign up",
  resetPasswordHeaderTitle: "Reset Password",
  mapNearbyNoOffer: "Coming soon",
  nearByofferTitle: `${titleNameP()} nearby`,
  cardDeleteMsg: `Are you sure? Once you've removed this card you will no longer be able to take part in TRAVX © rewards from this card. Please note that transactions that have not yet been processed will be lost if you delete this card.`,
  cardAddSuccessMsg:
    "Congratulations, your card has now been registered! Please note that while we aim to show transactions in your savings page within minutes, it does sometimes take up to 5 working days to process transactions.",
  cardHeaderTitle: "My Cards",
  noCarddLink: "No card linked",
  addNewCard: " Add new card",
  howitworkstxt: "How it works",
  removeCard: "Remove card",
  removeCardBtn: "Remove",
  cancelCardBtn: "Cancel",
  cardDeleteSuccessMsg: "Your card was deleted successfully",
  offerDetails: "Offer details",
  detailsPageTermsConditions: "For terms and conditions please",
  clickHere: "click here",
  getdirectionsBtn: "Get Directions",
  booknowBtn: "Book Now",
  supportFormHeaderTitle: "General Enquiry",
  supportSubMessage:
    "Thank you for getting in touch with Travx Support, our team is working on it and will get back to you shortly on your registered email address",
  fieldRequiredInfo: "* Fields must be entered to submit you general enquiry",
  SupportSubmitBtn: "Submit",
  ticketRaised: "Ticket successfully raised",
  ticketIdTxt: "Ticket Id",
  okBtn: "OK",
  profileHEaderTitle: "Edit your profile",
  profileSaved: "Profile saved",
  updateProfileBtn: "Update profile",
  profileFormFeildRequired:
    "* Fields must be entered to make changes to your profile",
  profileFullName: "Full name",
  profileEmail: "Email address",
  profilePhone: "Phone number",
  profileDOB: "Date of birth",
  profileNameError: "Name is required",
  profilePhoneError: "Phone number is required",
  profileDOBError: "Date of birth is required",
  countryCode: "Country code required",
  termOfServicesHeader: "Terms of service",
  cookiePolicyTitle: "Cookie Policy",
  noCardLinkedMsgSaving:
    "You have no card registered with us. Please add a card.",
  payOutMsg:
    "You need at least £50 in your payout balance to avail the payout facility",
  privacyPolicyTitle: "Privacy policy",
  termOfServices: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum`,
  legals: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum`,
  howItWorks: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum`,
};
