import AWS from 'aws-sdk';


const getS3Credentials = async () => {
	// Initialize an AWS Secrets Manager client
	const secretsManager = new AWS.SecretsManager({
		region: 'eu-west-2',
		credentials: {
			accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
			secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
		},
	});

	const secretName = 'amex_bulk_upload_s3_path';
	secretsManager.getSecretValue({ SecretId: secretName }, (err, data) => {
		if (err) {
			console.error('Error retrieving secret:', err);
		} else {
			// Parse the secret JSON string (assuming the secret is stored as JSON)
			const secretData = JSON.parse(data.SecretString);
			console.log(secretData)
			// Access S3 bucket credentials from the secret data
			const awsAccessKeyId = secretData.aws_access_key_id;
			const awsSecretAccessKey = secretData.aws_secret_access_key;
			const s3BucketName = secretData.s3_bucket_name;
	
			// Now you can use these credentials to interact with your S3 bucket
			// For example, using AWS SDK for S3:
			const AWS = require('aws-sdk');
			const s3 = new AWS.S3({
				accessKeyId: awsAccessKeyId,
				secretAccessKey: awsSecretAccessKey
			});

		}
	});
}

const uploadFileToS3 = async (selectedFile) => {
	try {
	const s3 = new AWS.S3({
		region: process.env.REACT_APP_AWS_REGION,
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
	});

	const params = {
		Bucket: process.env.REACT_APP_AWS_BULKUPLOAD_BUCKET_NAME,
		Key: `bulkUploadFiles/${new Date().getTime()}-${selectedFile.name}`,
		Body: selectedFile,
	};
	const data = await s3.upload(params).promise();
	return data.Location;
	} catch(e) {
		console.log(e);
		return false;
	}
}
export {
	getS3Credentials,
	uploadFileToS3
}