import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Link,
  Stack,
  Typography,
  Snackbar,
  IconButton,
  Backdrop,
  InputAdornment,
} from "@mui/material";
import {
  Contain,
  InputField1,
  LoginButton,
  SignupButton,
} from "../styles/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getOfferDetails } from "../services/fetchApis";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../components/Header";
import addBtn from "../assets/addBtn.png";

const EditBrandDetailsForm = () => {
  const params = useParams();
  const location = useLocation();
  const route = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const defaultNotify = { status: false, message: "" };
  const [notify, setNotify] = React.useState(defaultNotify);
  const initialErrorState = {
    email: "",
    password: "",
  };

  const [loginValue, setLoginValue] = React.useState({
    brandName: "",
    offerName: "",
    description: "",
    offerCategory: "",
    offerStartDate: "",
    offerEndDate: "",
  });
  const [error, setError] = React.useState(initialErrorState);

  const [offer, setOffer] = React.useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  //src={URL.createObjectURL(selectedImage)}
  useEffect(() => {
    console.log("location::", JSON.stringify(location));
    const data = location.state;
    if (data) {
      setLoginValue({
        brandName: data.brand_name,
        offerName: data.offer_name,
        description: data.copy,
        offerCategory: data.offer_category,
        offerStartDate: data.offer_start_date,
        offerEndDate: data.offer_end_date,
      });
    }
    // if (params.branchID) {
    //   offerDetails();
    // }
  }, [params]);

  const offerDetails = async () => {
    // setLoginValue({
    //   brandName: info.data.brand_name,
    //   branchAddress: info.data.branch_address,
    //   contactNumber: info.data.branch_contact_number,
    //   cuisines: info.data.cuisines_list.toString(),
    //   priceRange: info.data.price_range,
    //   discountPercentage: info.data.discount_percentage,
    //   description: info.data.branch_description,
    //   imageUrl: info.data.offer_creatives,
    //   bookingLink: info.data.booking_link,
    //   offerName: info.data.offer_name,
    //   coOrdinates:
    //     info.data.branch_latitude.toString() +
    //     " , " +
    //     info.data.branch_longitude.toString(),
    // });
    // try {
    //   setLoading(true);
    //   const body = {
    //     customer_id: "effa2a83-7cda-4197-bd89-9942912444fc",
    //     offer_id: params.id,
    //     is_detailed: 1,
    //     branch_id: params.branchID,
    //   };
    //   const info = await getOfferDetails(body);
    //   if (info.status === 200) {
    //     setOffer({ ...info.data });
    //     console.log("restaurant info:::", info.data);
    //     setLoginValue({
    //       brandName: info.data.brand_name,
    //       branchAddress: info.data.branch_address,
    //       contactNumber: info.data.branch_contact_number,
    //       cuisines: info.data.cuisines_list.toString(),
    //       priceRange: info.data.price_range,
    //       discountPercentage: info.data.discount_percentage,
    //       description: info.data.branch_description,
    //       imageUrl: info.data.offer_creatives,
    //       bookingLink: info.data.booking_link,
    //       offerName: info.data.offer_name,
    //       coOrdinates:
    //         info.data.branch_latitude.toString() +
    //         " , " +
    //         info.data.branch_longitude.toString(),
    //     });
    //     setLoading(false);
    //   }
    // } catch (error) {}
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleImageChange = async (event) => {
    console.log("runing");
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      console.log("first");
      const url = URL.createObjectURL(fileUploaded); // Create a URL for the selected file
      console.log("image URL", url);
      setLoginValue({ ...loginValue, imageUrl: url }); // Display the selected image
    }
    console.log(fileUploaded, "file");
    //const url = URL.createObjectURL(fileUploaded);
    //const url = URL.createObjectURL(selectedImage);
    //setLoginValue({ ...loginValue, imageUrl: url });
    //setSelectedImage(fileUploaded);
    //props.handleFile(fileUploaded);
  };
  const handleChange = (name) => (event) => {
    setError(initialErrorState);
    const val = event.target.value;
    // validate({ [name]: val });
    setLoginValue({ ...loginValue, [name]: val });
  };

  const resetForm = () => {
    setLoginValue({
      brandName: "",
      offerName: "",
      description: "",
      offerCategory: "",
      offerStartDate: "",
      offerEndDate: "",
    });
  };
  return (
    <>
      <Header />
      <Contain
        maxWidth="xl"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Stack
          className="mvh-100 auth-box"
          justifyContent="space-between"
          gap="1.25rem">
          <Box>
            <Box sx={{ padding: "2rem 0 0 0" }}>
              <Typography
                as="p"
                variant="h3"
                fontWeight={400}
                textAlign="center"
                className="brandom-medium"
                fontSize={"32px"}
                marginBottom={"17px"}
                letterSpacing="2px">
                Branch Details
              </Typography>
            </Box>
          </Box>
          {loading ? (
            <></>
          ) : (
            <>
              <Box marginTop={"30px"}>
                <Box className="mb-4">
                  <FormControl
                    fullWidth
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Brand Enable
                    </Typography>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl
                    fullWidth
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Branch Enable
                    </Typography>
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Brand Name
                    </Typography>
                    <InputField1
                      type="text"
                      name="brandName"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.brandName}
                      onChange={handleChange("brandName")}
                      //error={error.email ? true : false}
                    />
                    {/* {error.email ? (
								<Typography
									as='p'
									className='form-err-txt mt-2'>
									{error.email}
								</Typography>
							) : null} */}
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Offer Category
                    </Typography>
                    <InputField1
                      type="text"
                      name="offerCategory"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.offerCategory}
                      onChange={handleChange("cuisines")}
                      //error={error.email ? true : false}
                    />
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Offer Name
                    </Typography>
                    <InputField1
                      type="text"
                      name="offerName"
                      //placeholder={en.loginEmail//placeholder}
                      value={loginValue.offerName}
                      onChange={handleChange("offerName")}
                      //error={error.email ? true : false}
                    />
                  </FormControl>
                </Box>
                <Box className="mb-4">
                  <FormControl fullWidth>
                    <Typography
                      as="p"
                      variant="h4"
                      color="text.primary"
                      className="brandomtxt-regular mb-3">
                      Description
                    </Typography>
                    <textarea
                      rows={5}
                      type="text"
                      name="branch_description"
                      value={loginValue.description}
                      onChange={handleChange("description")}
                      className="brandomtxt-regular mb-3"
                      style={{
                        border: "none",
                        fontFamily: "sans-serif",
                        fontSize: "18px",
                        padding: "10px",
                        fontWeight: 400,
                        color: "#58595B",
                        borderRadius: "20px",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                  className="auth-stack">
                  <LoginButton
                    variant="dark"
                    // onClick={submitLoginForm}
                    className="brandomtxt-medium">
                    Save
                  </LoginButton>
                  <LoginButton
                    variant="dark"
                    onClick={resetForm}
                    className="brandomtxt-medium">
                    Reset
                  </LoginButton>
                </Stack>
              </Box>
            </>
          )}

          <div id="g_id_onload" style={{ margin: "20px auto" }}></div>
        </Stack>
      </Contain>
    </>
  );
};

export default EditBrandDetailsForm;
