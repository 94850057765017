import React from 'react';
import { LoginButton } from '../styles/styles';

const Modal = ({ isOpen, onClose, children , onDelete}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
        <div style={{ 
            display : 'flex',
            gap : "50px",
            justifyContent : 'center',
            alignItems : 'center'
        }}>
            			<LoginButton
							variant='dark'
							onClick={onClose}
							className='brandomtxt-medium'>
							Close
						</LoginButton>
                        <LoginButton
							variant='dark'
							onClick={onDelete}
							className='brandomtxt-medium'>
							Delete
						</LoginButton>
        {/* <button className="close-button" onClick={onClose}>
          Close
        </button>
            <div 
                style={{
                  cursor : 'pointer',
                  border : "2px solid red",
                  padding : "5px 10px",
                  borderRadius : "5px",
                  color : "red",
                  fontWeight : 'bold',
                  fontSize : "95%"
                }}
                onClick={()=>{
                  handleOpenModal(record)
            }}>
            Delete
            </div>
        <button className="close-button" onClick={onDelete}>
          Delete
        </button> */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
